import './SwitchToPayrollBanner.scss';

import { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { postJSON } from 'api/fetch';
import Box from 'fe-design-base/atoms/Box';
import Icon from 'fe-design-base/atoms/Icon';
import Text from 'fe-design-base/atoms/Text';
import Link from 'fe-design-base/molecules/Link';
import Pill from 'fe-design-base/molecules/Pill';
import { useCountDownTimer } from 'hooks/useCountDownTimer';

import { getCurrentLocationId, getCurrentUserId } from 'selectors/session';

import { cxHelpers } from 'util/className';
import { toI18n } from 'util/i18n';
import { PRODUCT_AREAS, TRACK_ACTION_TYPES } from 'util/tracking_constants';
import { useTrackUxHandler, withUxRoot } from 'util/uxEvents';

const { cx, cxEl } = cxHelpers('SwitchToPayrollBanner');

export const SwitchToPayrollBanner = ({
  currentUserId,
  currentLocationId,
}: {
  currentUserId: number;
  currentLocationId: number;
}) => {
  const browserLocation = useLocation();
  const pageIsPayrollPath = browserLocation.pathname.includes('payroll');

  const [showBanner, setShowBanner] = useState(!pageIsPayrollPath);

  const trackViewEvent = useTrackUxHandler(
    'EOY Banner Viewed',
    TRACK_ACTION_TYPES.VIEW
  );

  const trackCloseEvent = useTrackUxHandler(
    'EOY Banner Dismissed',
    TRACK_ACTION_TYPES.CLICK
  );

  const trackLinkClickEvent = useTrackUxHandler(
    'EOY Banner Clicked',
    TRACK_ACTION_TYPES.CLICK
  );

  useEffect(() => {
    setShowBanner(!pageIsPayrollPath);
  }, [pageIsPayrollPath]);

  useEffect(() => {
    if (showBanner) trackViewEvent();
  }, [showBanner, trackViewEvent]);

  const handleClose = useCallback(() => {
    trackCloseEvent();
    setShowBanner(false);
    return postJSON('/monetization/dismiss_eoy_banner', {
      location_id: currentLocationId,
      user_id: currentUserId,
    });
  }, [currentLocationId, currentUserId, trackCloseEvent]);

  const onHideTimer = useCallback(() => setShowBanner(false), []);

  const timeLeft = useCountDownTimer({
    onHideTimer,
    hideTimer: !showBanner,
  });

  if (!showBanner || !timeLeft) {
    return null;
  }

  return (
    <Box
      row
      alignItemsCenter
      ph={16}
      pv={25}
      className={cx()}
      bgcolor="primary100"
    >
      <Box
        wrap
        justifyContentCenter
        gap={8}
        ph={16}
        grow={1}
        color="primary900"
      >
        <Box className={cxEl('pill')}>
          <Pill variant="highlight">
            {toI18n('switch_to_payroll_banner.till_deadline', {
              props: {
                count: timeLeft.days,
                formated_count: timeLeft.days,
              },
            })}
          </Pill>
        </Box>

        <Box row wrap justifyContentCenter gap={3}>
          <Text
            variant="bodyBold"
            i18n="switch_to_payroll_banner.payroll_for_hourly_teams"
          />

          <Text i18n="switch_to_payroll_banner.automated_and_accurate" />
        </Box>

        <Link
          onClick={trackLinkClickEvent}
          // eslint-disable-next-line max-len
          href={`/payroll?utm_source=${window.Homebase.env}&utm_medium=in_app_banner&utm_campaign=pr24&utm_content=primary_banner&utm_term=1&from_eoy_payroll_banner=true`}
        >
          {toI18n('switch_to_payroll_banner.switch_to_payroll')}
        </Link>
      </Box>

      <Box>
        <Icon
          onClick={handleClose}
          size="small"
          iconName="Close"
          color="primary500"
          dataTestId="dismiss-icon"
        />
      </Box>
    </Box>
  );
};

export default connect(
  state => ({
    currentUserId: getCurrentUserId(state),
    currentLocationId: getCurrentLocationId(state),
  }),
  {}
)(
  withUxRoot({
    productArea: PRODUCT_AREAS.GLOBAL,
    eventCategory: 'payroll_eoy_banner',
  })(SwitchToPayrollBanner)
);
